import React, { useMemo, useState } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react';
import './App.css'
import * as Sentry from "@sentry/react";
import { msalInstance } from './utils/azureAdB2cService/msalSetup';
import { MsalAuthComponent } from './utils/azureAdB2cService/MsalAuthComponent';
import {
	QueryClient,
	QueryClientProvider
  } from "@tanstack/react-query";
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import PageLoader from './app/components/shared/PageLoader';
import ErrorBoundary from './app/components/shared/ErrorBoundary';
import { ProposedFilingQuery } from './utils/typings/Applications';
import { AppContext } from './utils/AppContext';

const NotFoundComponent = React.lazy(() => import('./app/components/shared/NotFoundComponent'))
const Homepage = React.lazy(() => import('./app/pages/home/Home'))
const ApplyForPartner = React.lazy(() => import('./app/pages/home/Apply'))
const Dashboard = React.lazy(() => import('./app/pages/dashboard/Dashboard'))
const QueryManagement = React.lazy(() => import('./app/pages/applications/QueriedApplications'))
const Notifications = React.lazy(() => import('./app/pages/notifications/Notifications'))
const Applications = React.lazy(() => import('./app/pages/applications/Applications'))
const ApplicationDetails = React.lazy(() => import('./app/pages/applications/ApplicationDetails'))
const EditApplication = React.lazy(() => import('./app/pages/applications/EditApplication'))
const Settings = React.lazy(() => import('./app/pages/settings/Settings'))
const Services = React.lazy(() => import('./app/pages/services/Services'))
const ServiceDetails = React.lazy(() => import('./app/pages/services/ServiceDetails'))

const NotificationsComponent = () => {
	return MsalAuthComponent(Notifications);
};
const DashboardComponent = () => {
	return MsalAuthComponent(Dashboard);
};
const QueryManagementComponent = () => {
	return MsalAuthComponent(QueryManagement);
};
const ApplicationsComponent = () => {
	return MsalAuthComponent(Applications);
};
const ApplicationDetailsComponent = () => {
	return MsalAuthComponent(ApplicationDetails);
};
const EditApplicationComponent = () => {
	return MsalAuthComponent(EditApplication);
};
const SettingsComponent = () => {
	return MsalAuthComponent(Settings);
};
const ServicesComponent = () => {
	return MsalAuthComponent(Services);
};
const ServiceDetailsComponent = () => {
	return MsalAuthComponent(ServiceDetails);
};


const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 45*(60*1000), // 45 mins
			cacheTime: 50*(60*1000), // 50 mins
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
})




function App() {

	const [queryTypes, setQueryTypes] = useState<string[]>([])
	const [proposedQueryDetails, setProposedQueryDetails] = useState<ProposedFilingQuery>({
		id: '',
		applicationId: '',
		applicationName: '',
		companyName: '',
		description: '',
		queryType: [],
		approvedBy: '',
		isApproved: false,
		dateCreated: '',
		dateUpdated: '',
		companyId: '',
		dateApproved: '',
		customerEmail: '',
		applicationQueryIds: [],
		applicationQueryCount: 0,
		approvedQueryTypes: []
	})

	const contextData = useMemo(() => ({
		queryManagement: {
			queryTypes,
			setQueryTypes: (type:string[]) => setQueryTypes(type),
			proposedQueryDetails,
			setProposedQueryDetails: (details:ProposedFilingQuery) => setProposedQueryDetails(details)
		}
	}), [queryTypes, proposedQueryDetails])
	
	
	window.addEventListener('vite:preloadError', (event) => {
		window.location.reload()
	})

    return (
        <Sentry.ErrorBoundary fallback={(error) => <ErrorBoundary error={error} />} showDialog>
			<MsalProvider instance={msalInstance}>
                <AppContext.Provider value={contextData}>
                    <React.Suspense fallback={<PageLoader/>} >
                        <BrowserRouter>
                            <ToastContainer
                                position="top-right"
                                hideProgressBar={true}
                                autoClose={2000}
                                newestOnTop={true}
                                closeOnClick
                                pauseOnHover
                                theme="colored"
                                transition={Slide}
                            />
                            <QueryClientProvider client={queryClient}>
                                <Routes>
                                    <Route path='/' element={<Homepage/>}/>
                                    <Route path='applications' element={<DashboardComponent/>} >
                                        <Route index element={<ApplicationsComponent/>} />
                                        <Route path=':id' element={<ApplicationDetailsComponent/>}/>
                                    </Route>
                                    <Route path='edit' element={<DashboardComponent/>}  >
                                        <Route path=':id' element={<EditApplicationComponent/>} />
                                    </Route>
                                    <Route path='query-management' element={<DashboardComponent/>}  >
                                        <Route index element={<QueryManagementComponent/>} />
                                    </Route>
                                    <Route path='/apply' element={<ApplyForPartner/>}  />
                                    <Route path='settings' element={<DashboardComponent/>} > 
                                        <Route index element={<SettingsComponent/>} />
                                    </Route>
                                    <Route path='services' element={<DashboardComponent/>} >
                                        <Route index element={<ServicesComponent/>} />
                                        <Route path=':timelineid' element={<ServiceDetailsComponent/>} />
                                    </Route>
                                    <Route path='notifications' element={<DashboardComponent/>}>
                                        <Route index element={<NotificationsComponent/>} />
                                    </Route>
                                    <Route path="*" element={<NotFoundComponent />} />
                                </Routes>
                            </QueryClientProvider>
                        </BrowserRouter>
                    </React.Suspense>
                </AppContext.Provider>
			</MsalProvider>
        </Sentry.ErrorBoundary>
    )
}

export default App
