import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from '@sentry/browser'
import App from './App'
import './index.css'
// https://partners-dev-fnd-webapp.azurewebsites.net/

const domainName = window.location.hostname;
let isEnable = false;

const ResolveUrl =()=>{
	switch(true){
		case domainName.toLowerCase().indexOf('df')>-1:
			isEnable = false;
			break;
		case domainName.toLowerCase().indexOf('dev')>-1:
		case domainName.toLowerCase().indexOf('localhost')>-1:
			isEnable = false;
			break;
		default:
		isEnable = true
	}
}
ResolveUrl()

const potentialErrors = [
	'interaction_in_progress: Interaction is currently in progress. Please ensure that this interaction has been completed before calling an interactive API.  For more visit: aka.ms/msaljs/browser-errors.',
	'no_cached_authority_error: No cached authority found.',
	"endpoints_resolution_error: Error: could not resolve endpoints. Please check network and try again. Detail: ClientAuthError: openid_config_error: ",
	'Failed to fetch dynamically imported module',
    'Unable to preload CSS',
    'error loading dynamically imported module'
]

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN_KEY,
	integrations: [new BrowserTracing(), new Sentry.Replay()],
	debug: true,
	beforeSend(event, hint) {
		console.log(event)
		console.log(hint)
		const error = hint.originalException as {[key:string]: string};
		const userData = JSON.parse(localStorage.getItem('PartnerUserData')!)
		//IF return null, event will be discarded
		if(error?.message){
			let isPotentialError = potentialErrors.some(e => error?.message?.includes(e))
			if(isPotentialError){
				return null
			}
		}
		if (event.exception) {
			Sentry.showReportDialog({ 
				eventId: event.event_id,
				dsn: import.meta.env.VITE_SENTRY_DSN_KEY,
				user: {
					email: userData?.email ?? 'N/A',
					name: userData?.displayName ?? 'N/A'
				}
			});
		}
		return event
	},
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
	enabled: isEnable,
});


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
)
