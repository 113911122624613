// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig, loginRequest } from './azureAdB2cServiceConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  console.log(msalInstance)
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event:any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    localStorage.removeItem('partners_access_token');
    msalInstance.setActiveAccount(account);
  }
  else if (event.eventType === EventType.LOGIN_FAILURE) {
    if (event.error.errorMessage.indexOf("AADB2C90091") > -1) {
      msalInstance.loginRedirect(loginRequest);
    }
  }
  else if(event.eventType==EventType.ACQUIRE_TOKEN_FAILURE && event.error.name==='InteractionRequiredAuthError'){
    msalInstance.logoutRedirect();
  }
});