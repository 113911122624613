import React from 'react'
import Loader from '../ui/Loader'

function PageLoader() {
    return (
        <div className='fixed z-[100] h-screen top-0 left-0 flex items-center justify-center w-full bg-darkGray'>
            <div className='text-white'>
                <Loader/>
                <p className='text-xl mt-5'>Loading page...</p>
            </div>
        </div>
    )
}

export default PageLoader